<template>
  <section class="open-soft">
    正在打开【礼帽出行】应用...
  </section>
</template>

<script>
export default {
  components: {
  },
  mounted(){
    // window.location.href = 'https://www.limaocx.com/passengerApp.html'
  },
  methods: {
   
  }

}
</script>

<style>
 .open-soft {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #999;
 }
</style>
